<template>
  <div class="container">
    <!-- <div class="box">
      <router-link to="/phone">绑定手机</router-link>
      <a href="/phone">绑定手机</a>
    </div> -->
    <div class="box">
      <a href="javascript:;" @click="getPolicy('fwxy')">服务协议</a>
      <a href="javascript:;" @click="getPolicy('yszc')">隐私政策</a>
      <a href="javascript:;" @click="getPolicy('mzsm')">免责申明</a>
      <a href="javascript:;" @click="getPolicy('gywm')">关于我们</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Set',
    data () {
      return{

      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      console.log('系统设置')
    },
    methods: {
      getPolicy(code) {
        this.$router.push({
          path:'/policy',
          query:{
            code: code
          }
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 0 0 20px 0;
    box-sizing: border-box;
    .box{
      border-top: 5px solid #f0f0f0;
      a{
        display: block;
        background: url(../assets/img/icon-more.png) no-repeat 95% center/auto 15px #fff;
        text-align: left;
        padding: 10px 20px;
        font-size: 16px;
        line-height: 26px;
        color: #141212;
        border-bottom: 1px solid #f1f1f1;
      }
    }
    .box:first-of-type{
      border: none;
    }
  }
</style>
